import React from 'react';
import Head from "next/head";
import axios from './../lib/axios';
import Footer from '../components/footer';
import HeroHeader from '../components/hero-header';
import { Categories } from '../components/categories';

export async function getServerSideProps({ query }) {
    const [categories, location] = await Promise.all([
        axios.get('api/v1/categories'),
        axios.get('api/v1/locations/get-location')
    ]);
    return {
        props: {
            categories: categories.data,
            currentLocation: location.data,
            query
        }
    };
}

function Home({ currentLocation, categories }) {
    return (
        <div>
            <Head>
                <title>{process.env.APP_NAME.concat(" - Cần thợ nails trên 50 tiểu bang")}</title>
                <meta name="Description" content="Cần thợ nails trên khắp 50 tiểu bang Hoa Kỳ." />
                <meta name="facebook-domain-verification" content="or2sskicub9zo9k70yewt6j132urj8" />
            </Head>
            <HeroHeader currentLocation={currentLocation} />
            <div className="bg-gray-100 pb-4 lg:py-10">
                <Categories categories={categories} currentLocation={currentLocation}></Categories>
            </div>
            <Footer />
        </div>
    )
}

export default Home;

