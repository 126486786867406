import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import CategoryIcon from './category-icon';

export const Categories = (props) => {
    const [locationSlug, setLocationSlug] = useState('');
    useEffect(() => {
        if (props.currentLocation) {
            const slug = props.currentLocation?.text?.replace(/,*\s/g, '-').toLowerCase();
            setLocationSlug(slug);
        }
    }, [props.currentLocation])

    const trackCategoryClick = (category) => {
        const attributes = {
            category: 'home',
            action: 'category-click',
            value: category.name,
        }
        // logEvent(attributes);
    }


    const renderCategoryItem = props.categories?.map((category) =>
        <li className="transform hover:scale-102 transition duration-100 ease-in-out hover:shadow-xl"
            key={category.id}>
            <Link href={
                {
                    pathname: "/listing/[location]/[category]",
                    query: { location: locationSlug, category: category.slug }
                }
            } >
                <span onClick={() => trackCategoryClick(category)} className="flex h-full w-full flex-col justify-start relative z-0 bg-white shadow rounded-md p-6 md:p-8">
                    <CategoryIcon category={category} />
                    <div className="mt-6">
                        <span className="text-dark text-lg font-semibold">{category.name}</span>
                        <p className="text-sm text-gray-500">{category.description}</p>
                    </div>
                </span>
            </Link>
        </li >
    );

    const renderPostButtonAdAsCategory = (
        <li className="lg:hidden transform hover:scale-102 transition duration-100 ease-in-out hover:shadow-xl">
            <Link href="/account/ads/new-ad?category=1">
                <span className="flex h-full w-full flex-col justify-start relative z-0 bg-white shadow rounded-md p-6 md:p-8">
                    <CategoryIcon category={{ id: -1, colour: { iconColor: "#FFF", iconBackground: "#7782F7" } }} />
                    <div className="mt-6">
                        <span className="text-dark text-lg font-semibold">Đăng Rao Vặt</span>
                        <p className="text-sm text-gray-500">Nhanh chóng, hiệu quả và tiện lợi</p>
                    </div>
                </span>
            </Link>
        </li>
    );

    return (
        <div className="container mx-auto">
            <div className="h-full mx-2 md:mx-3">
                <div className="text-center my-12 hidden lg:block">
                    <h2 className="font-light text-3xl mt-5 text-gray-600">Categories</h2>
                </div>
                <ul className="grid grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 rounded-md">
                    {renderCategoryItem}
                    {renderPostButtonAdAsCategory}
                </ul>
            </div>
        </div>
    )
}