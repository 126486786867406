import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/pro-duotone-svg-icons'
import Image from 'next/image'
import '../styles/nvusa/hero-header.module.scss';
import LocationSelection from './location-selection';
import Header from './header';


export default function HeroHeader({ currentLocation }) {

    return (
        <div className="lg:px-0 lg:bg-blue-100 flex flex-col relative lg:h-full">
            <Header isShowSearchBox={true} className="lg:bg-blue-100 border-gray-150 lg:border-blue-100" />
            <div className="bg-gray-100 lg:bg-blue-100 py-4 md:px-2">
                <div className="container mx-auto">
                    <div className="flex h-full relative bg-dark z-10 shadow lg:shadow-none rounded-md p-3 mx-2 lg:p-0 lg:bg-transparent lg:rounded-none">
                        <div className="intro w-full lg:w-6/12 flex-shrink-0 lg:pr-5">
                            <h2 className="font-light text-orange-600 text-3xl mt-10 hidden lg:block">Hello, Welcome to Nailify</h2>
                            <h1 className="w-7/12 lg:w-full text-2xl text-white lg:text-6xl lg:text-dark font-bold lg:leading-tight p-3 lg:p-0">
                                Cần thợ nails trên 50 tiểu bang.
                            </h1>
                            {/* <div className="p-3">
                                <LocationSelection initialLocation={currentLocation} />
                            </div> */}

                            <div className="h-0 lg:h-48">
                            </div>
                        </div>
                        <div className="intro-images w-0 lg:w-6/12 hidden lg:flex items-center justify-end relative">
                            <div className="absolute top-10 left-16 pointer-events-none w-40">
                                <Image
                                    src="/static/images/header/pattern03.svg"
                                    width={188}
                                    height={116}
                                    alt="gray dots"
                                    className="object-cover w-full h-full"
                                />
                            </div>
                            <div className="absolute bottom-10 xl:right-8 pointer-events-none w-40">
                                <Image
                                    src="/static/images/header/pattern03.svg"
                                    width={188}
                                    height={116}
                                    alt="gray dots"
                                    className="object-cover w-full h-full"
                                />
                            </div>
                            <div className="absolute bottom-0 left-8 mb-8 z-40 shadow-md overflow-hidden rounded-md">
                                <div className="transition duration-500 ease-in-out transform hover:scale-110 h-46 w-52">
                                    <Image
                                        src="/static/images/header/pho.jpg"
                                        width={340}
                                        height={277}
                                        alt="pho"
                                        className="object-cover w-full h-full rounded-md"
                                    />
                                </div>
                            </div>
                            <div className="absolute bottom-28 left-36 shadow-md overflow-hidden rounded-md">
                                <div className="rounded-lg transition duration-500 ease-in-out transform hover:scale-110 h-80 w-72">
                                    <Image
                                        src="/static/images/header/manicurist.jpg"
                                        width={376}
                                        height={394}
                                        alt="manicurist"
                                        className="object-cover w-full h-full rounded-md"
                                    />
                                </div>
                            </div>
                            <div className="absolute z-40 top-0 right-0 xl:top-8 xl:right-16 shadow-md overflow-hidden rounded-lg">
                                <div className="transition duration-500 ease-in-out transform hover:scale-110 w-32 h-32">
                                    <Image
                                        src="/static/images/header/sua-xe.jpg"
                                        width={270}
                                        height={180}
                                        alt="Mechanic"
                                        className="object-cover w-full h-full rounded-md"
                                    />
                                </div>
                            </div>
                            <div className="absolute z-40 bottom-16 right-10 xl:bottom-16 xl:right-24 w-24 h-24 bg-purple-500 flex justify-center items-center rounded-full shadow-md pointer-events-none">
                                <span className="text-5xl text-white">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
